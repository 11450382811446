import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();


//Navigation Menu
// $(function() {
//   $(window).scroll(function() {
//     var winTop = $(window).scrollTop();
//     if (winTop >= 30) {
//       $("body").addClass("sticky-shrinknav-wrapper");
//     } else{
//       $("body").removeClass("sticky-shrinknav-wrapper");
//     }
//   });
// });

/* ====================================================
    Adding class to mobile main menu
   ==================================================== */

$(document).ready(function ($) {
  // Get current url
  // Select an a element that has the matching href and apply a class of 'active'. Also prepend a - to the content of the link
  var url = window.location.href; // Will also work for relative and absolute hrefs

  $('.main-menu ul.menu a').each(function () {
    if (this.href == window.location.href) {
      $(this).parents('li').find('> a').addClass("active");
    }
  });
  $('.mega-menu-container ul.menu a').each(function () {
    if (this.href == window.location.href) {
      $(this).parents('li').find('> a').addClass("active");
    }
  });
});


/* ====================================================
    Gallery Filter
   ==================================================== */ 
// shows and hides filtered items
$(".filter-simple-button").click(function() {
  var value = $(this).attr('data-filter');
  if(value === "all") {
    $('.filter-simple-item').show('1000');
  } else {
    $(".filter-simple-item").not('.'+value).hide('3000');
    $('.filter-simple-item').filter('.'+value).show('3000');
  }
});

// changes active class on filter buttons
$('.filter-simple-button').click(function () {
  $(this).siblings().removeClass('is-active');
  $(this).addClass('is-active');
});

// shows and hides filtered items
$(".filter-simple-button2").click(function() {
  var value = $(this).attr('data-filter');
  if(value === "all2") {
    $('.filter-simple-item2').show('1000');
  } else {
    $(".filter-simple-item2").not('.'+value).hide('3000');
    $('.filter-simple-item2').filter('.'+value).show('3000');
  }
});

// changes active class on filter buttons
$('.filter-simple-button2').click(function () {
  $(this).siblings().removeClass('is-active');
  $(this).addClass('is-active');
});


/* ====================================================
    Animation ScrollMagic Reveal
   ==================================================== */ 
$(document).ready(function() {


  // init controller
  var controller = new ScrollMagic.Controller();

  var revealElements = document.getElementsByClassName("appear");
  for (var i=0; i<revealElements.length; i++) { // create a scene for each element
    new ScrollMagic.Scene({
              triggerElement: revealElements[i], // y value not modified, so we can use element as trigger as well
              offset: 50,                        // start a little later
              triggerHook: 0.9,
              reverse: false,
            })
            .setClassToggle(revealElements[i], "visible") // add class toggle
            .addIndicators({name: "appear " + (i+1) }) // add indicators (requires plugin)
            .addTo(controller);
  }

  
});


/* ====================================================
    Reveal Modal for Images
   ==================================================== */ 
// Get the modal
var modal = document.getElementById("imgModal");

// to all images -- note I'm using a class!
var images = document.getElementsByClassName('myImg');
var modalImg = document.getElementById("img01");

// Go through all of the images with our custom class
for (var i = 0; i < images.length; i++) {
  var img = images[i];
  // and attach our click listener for this image.
  img.onclick = function(evt) {
    modal.style.display = "block";
    modalImg.src = this.src;
    captionText.innerHTML = this.alt;
  }
}


$('.menu-icon').click(function(){
   $(".top-bar").toggleClass("mobile-nav");
   $(this).toggleClass("is-active");
});
